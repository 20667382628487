@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
    box-sizing: border-box;
    height: 100%;
    min-height: -webkit-fill-available;
}

input[type=date]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type=number] {
    border: none;
}

input[type=number]:focus {
    outline: none;
    --tw-ring-color: transparent;
}

input[type=checkbox]:focus {
    outline: none;
    --tw-ring-color: transparent;
}

.delete_outline:focus {
    outline: none;
}

.special_radial_gradient {
    background: radial-gradient(circle, #2169AC 0%, #2169AC 42%, #FFF 47%);
}

.ani_img_botxi {
    animation: rotate 0.5s infinite linear alternate
}

@keyframes rotate {
    0% {
        transform: rotate(-30deg);
    }

    100% {
        transform: rotate(30deg);
    }
}

.scrolling-wrapper {
    -webkit-overflow-scrolling: touch;
}

.signupselect {
    background: url(https://i.ibb.co/fCsFTVL/icn-dropdown-chevron.png) no-repeat;
    width: 15px;
    height: 8px;
    background-size: contain;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #00000000;
}

::-webkit-scrollbar-thumb {
    background: #C2D2E4;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #C2D2E4;
}

.scroller {
    scrollbar-color: #C2D2E4 #00000000;
}

.react-datepicker {
    border: 1px solid #FFF !important;
    border-radius: 20px !important;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__header {
    background-color: #FFF !important;
    padding: 0.75rem !important;
    border: 1px solid #FFF;
    border-bottom: none !important;
}

.react-datepicker__current-month {
    color: rgb(17 24 39) !important;
    font-weight: 400 !important;
}

.react-datepicker__navigation-icon::before {
    border-color: rgb(17 24 39) !important;
    margin-top: 12px !important;
}

.react-datepicker__navigation-icon:hover::before {
    border-color: #777 !important;
}

.react-datepicker__day-name {
    color: rgb(17 24 39) !important;
}

.react-datepicker__day--selected {
    border-radius: 100% !important;
}

.react-datepicker__day:hover {
    border-radius: 100% !important;
}

.react-datepicker-year-header {
    color: rgb(17 24 39) !important;
    font-weight: 400 !important;
}

.react-datepicker__month {
    margin: 0rem !important;
    padding: 0 1rem 1rem 1rem !important;
}

.react-datepicker__month-wrapper {
    margin: 0.4rem !important
}

.react-datepicker__month-text {
    padding: 0.4rem 0 !important;
    width: 5rem !important;
}

.react-datepicker__month-text--selected {
    background-color: #146DBF !important;
}

.DatePicker__input {
    text-align: left !important;
    padding: 0.625rem 0.5rem !important;
    font-size: 1rem !important;
}

.input-invisible {
    margin-top: 0px;
    overflow: hidden;
    visibility: hidden;
	opacity: 0;
	transition: visibility 0s 0.2s, opacity 0.2s linear, margin-top 0s 0.2s;
}

.input-visible {
    margin-top: 1.5rem;
    visibility: visible;
	opacity: 1;
	transition: opacity 0.3s linear;
}

.input-invisible > div {
    transition: margin-top 0s 0.2s;
    margin-top: -80px;
} 

.react-datepicker__year{
    margin: 0rem!important;
    padding: 0 1rem 1rem 1rem !important;
}

.react-datepicker__month-wrapper{
    margin: 0.4rem!important
}

.react-datepicker__month-text{
    padding: 0.4rem 0 !important;
}
.react-datepicker__year-text{
    padding: 0.4rem 0 !important;
}
.react-datepicker__year .react-datepicker__year-text{
    width: 3.5rem !important;
}
.react-datepicker__month-text--selected{
    background-color: #146DBF!important;
}
.react-datepicker__year-text--selected{
    background-color: #146DBF!important;
}